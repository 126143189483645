/**
 * Injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * Injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * Injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

@layer utilities {
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }
    .beacon-drawer {
        z-index: 1005;
        border: 1px solid rgba(193, 203, 212, 0.7);
    }
    .drawer-enter-active {
        opacity: 0;
        display: block;
        transition: opacity 450ms cubic-bezier(0.19, 1, 0.22, 1) 100ms;
    }
    .drawer-enter-done {
        opacity: 1;
        transition: opacity 450ms cubic-bezier(0.19, 1, 0.22, 1) 100ms;
    }
    .drawer-exit-active {
        opacity: 0;
        transition: opacity 450ms cubic-bezier(0.19, 1, 0.22, 1) 100ms;
    }
    .drawer-exit-done {
        display: none;
    }
    /* Text-decoration is covered in Tailwindcss 3 */
    .link-underline {
        text-decoration: underline dotted;
    }
    .link-underline:hover {
        text-decoration: underline solid;
    }
}
